import Vue from "vue";
import Router from "vue-router";
import store from "./store/store";
import { auth } from "@/auth/auth";

Vue.use(Router);
const appName = process.env.VUE_APP_NAME == "RelloPay" ? "Rello" : "FlipPay";

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/admin",
      redirect: "/admin/dashboard",
      name: "Admin",
      meta: {
        rule: "admin",
      },
      component: () => import("./layouts/main/Main.vue"),
      children: [
        {
          path: "/quick-setup",
          name: "quick-setup",
          component: () => import("./views/pages/partners/quickSetup/welcomePage.vue"),
          meta: {
            pageTitle: "Quick start",
            rule: "admin"
          },
        },
      ],
    },
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          redirect: "/login",
        },
        {
          path: "/dashboard",
          name: "partner-dashboard",
          component: () => import("@/views/pages/partners/Dashboard.vue"),
          meta: {
            requiresAuth: true,
            rule: "editor",
          },
        },

        {
          path: "/new-payment-requests/:reqTemId/create",
          name: "payment-request-create",
          component: () => import("@/views/pages/partners/pages/paymentRequests/paymentRequestForm.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Send request", active: true },
            ],
            pageTitle: "Send request",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/new-payment-requests/:partnerId/:customPlanId/create",
          name: "franchisee-payment-request-create",
          component: () =>
            import(
              "@/views/pages/partners/pages/paymentRequests/paymentRequestForm.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Send request", active: true },
            ],
            pageTitle: "Send request",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/payment-requests/:id",
          name: "staff-payment-requests-detail",
          component: () => import("@/views/pages/partners/paymentRequest/PaymentRequestDetails/PaymentRequestDetails.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "View payment request", active: true },
            ],
            pageTitle: "View payment request",
            requiresAuth: true,
            rule: "editor",
          }
        },
        {
          path: "/payment-request-template-communication",
          name: "payment-request-template-communication",
          component: () => import("@/views/settings/merchant/PaymentRequestCommunication.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              {
                title: " Default communications settings - Payment requests",
                active: true,
              },
            ],
            pageTitle: "Default communications settings - Payment requests",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/hosted-page-communication",
          name: "hosted-page-communication",
          component: () => import("@/views/settings/merchant/HostedPageCommunication.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              {
                title: "Default communications settings - Hosted payment pages",
                active: true,
              },
            ],
            pageTitle: "Default communications settings - Hosted payment pages",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/transactions",
          name: "staff-transactions",
          component: () => import("@/views/pages/partners/transaction/Transaction.vue"),
          meta: {
            pageTitle: "Transactions",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/transactions/:id",
          name: "staff-transactions-details",
          component: () => import("@/views/pages/partners/transaction/TransactionDetail.vue"),
          meta: {
            pageTitle: "Transaction",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/settings",
          name: "settings",
          component: () => import("@/views/settings/merchant/Settings.vue"),
          meta: {
            pageTitle: "Settings",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/payment-request-template",
          name: "payment-request-template",
          component: () => import("@/views/settings/merchant/paymentRequestTemplateList.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Templates & HPP", active: true },
            ],
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/help",
          name: "help",
          component: () => import("@/views/settings/merchant/Help.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Help", active: true },
            ],
            pageTitle: "Help",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/hosted-page",
          name: "hosted-page",
          component: () => import("@/views/settings/merchant/HostedPage.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Hosted payment pages - pages", active: true },
            ],
            pageTitle: "Hosted Payment Pages",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/payment-request-template/:templateType",
          name: "payment-request-template-create",
          component: () => import("@/views/pages/partners/pages/paymentRequestTemplate/TemplateForm.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              {
                title: "Payment request templates",
                url: "/payment-request-template",
              },
            ],
            pageTitle: "New payment request template",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/payment-request-template-edit/:templateType/:id",
          name: "payment-request-template-edit",
          component: () => import("@/views/pages/partners/pages/paymentRequestTemplate/TemplateForm.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              {
                title: "Payment request templates",
                url: "/payment-request-template",
              },
            ],
            pageTitle: "Edit payment request template",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/hosted-page/create",
          name: "hosted-page-create",
          component: () => import("@/views/pages/partners/pages/hostedPaymentPage/TemplateForm.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Hosted payment pages", url: "/hosted-page" },
              { title: "New hosted payment page", active: true },
            ],
            pageTitle: "New hosted payment page",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/hosted-page/:id",
          name: "hosted-page-edit",
          component: () => import("@/views/pages/partners/pages/hostedPaymentPage/TemplateForm.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Hosted payment pages", url: "/hosted-page" },
              { title: "Edit hosted payment page", active: true },
            ],
            pageTitle: "Edit hosted payment page",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/contact-details",
          name: "contact-details",
          component: () => import("@/views/settings/merchant/defaultCompanyHeader.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              {
                title: "Default branding & contact details",
                active: true,
              },
            ],
            pageTitle: "Default branding & contact details",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/branding-style",
          name: "branding-style",
          component: () => import("@/views/settings/merchant/BrandingStyle.vue"),
          meta: {
            pageTitle: "Branding & style",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/custom-fields",
          name: "custom-fields",
          component: () => import("@/views/pages/partners/pages/paymentRequestTemplate/customFields.vue"),
          meta: {
            pageTitle: "Custom Fields",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },

        {
          path: "/customer-portal",
          name: "customer-portal",
          component: () => import("@/views/settings/merchant/CustomerPortal.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              {
                title: "Customer self-service portal - link to portal",
                active: true,
              },
            ],
            pageTitle: "Customer self-service portal - link to portal",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/customer-portal-branding",
          name: "customer-portal-branding",
          component: () => import("@/views/settings/merchant/PortalBranding.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              {
                title: "Customer self-service portal - branding",
                active: true,
              },
            ],
            pageTitle: "Customer self-service portal - branding",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/default-company-header",
          name: "default-company-header",
          component: () => import("@/views/settings/merchant/defaultCompanyHeader.vue"),
          meta: {
            pageTitle: "Company Header",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/pages/custom-field/create",
          name: "custom-field-create",
          component: () => import("@/views/pages/partners/pages/customFields/CustomFieldForm.vue"),
          meta: {
            breadcrumb: [
              { title: "Edit", active: true },
            ],
            pageTitle: "Custom field",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/pages/custom-field/:id/edit",
          name: "custom-field-edit",
          component: () => import("@/views/pages/partners/pages/customFields/CustomFieldForm.vue"),
          meta: {
            breadcrumb: [
              { title: "Edit", active: true },
            ],
            pageTitle: "Custom field edit",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/users",
          name: "partner-users",
          component: () => import("@/views/pages/partners/users/ListUser.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Access & permissions - users", active: true },
            ],
            pageTitle: "Access & permissions - users",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },

        {
          path: "/users/create",
          name: "partner-users-create",
          component: () => import("@/views/pages/partners/users/UserForm.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Access & permissions - users", url: "/users" },
              { title: "New user", active: true },
            ],
            pageTitle: "New user",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/users/:id/edit",
          name: "partner-users-edit",
          component: () => import("@/views/pages/partners/users/UserForm.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Access & permissions - users", url: "/users" },
              { title: "Edit user", active: true },
            ],
            pageTitle: "Edit user",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/view/profile",
          name: "admin-view-profile",
          component: () => import("@/views/pages/adminProfileNew.vue"),
          meta: {
            rule: "editor",
            pageTitle: "User profile ",
            requiresAuth: true,
          },
        },
        {
          path: "/integrations",
          name: "integrations",
          component: () => import("@/views/settings/merchant/LinkedSystem.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Access & permissions - integrations", active: true },
            ],
            pageTitle: "Access & permissions - integrations",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/integrations/boxanddice/:type/:introducerId",
          name: "box-and-dice-integrations",
          component: () => import("@/views/settings/merchant/BoxAndDiceIntegration.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Integrations", url: "/integrations" },
              { title: "Box & Dice - settings", active: true },
            ],
            pageTitle: "Box & Dice - settings",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/api-token/create",
          name: "api-token-create",
          component: () => import("@/views/settings/merchant/ApiToken.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Access & permissions - integrations", url: "/integrations" },
              { title: "New API token", active: true },
            ],
            pageTitle: "New API Token",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/api-token/:id",
          name: "api-token-edit",
          component: () => import("@/views/settings/merchant/ApiToken.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Access & permissions - integrations", url: "/integrations" },
              { title: "Edit API token", active: true },
            ],
            pageTitle: "Edit API Token",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/linked-system/:id",
          name: "linked-system-detail",
          component: () => import("@/views/settings/merchant/LinkedSystemDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Integrations - linked systems", url: "/linked-system" },
              { title: "View", active: true },
            ],
            pageTitle: "View",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/bank-accounts",
          name: "bank-accounts",
          component: () => import("@/views/settings/merchant/BankAccounts.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Account configuration - my bank accounts", active: true },
            ],
            pageTitle: "Account configuration - my bank accounts",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/platform-bank-accounts",
          name: "platform-bank-accounts",
          component: () => import("@/views/settings/merchant/PlatformBankAccounts.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: `Account configuration - ${appName} bank accounts`, active: true },
            ],
            pageTitle: `Account configuration - ${appName} bank accounts`,
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/saved-external-accounts",
          name: "saved-external-accounts",
          component: () => import("@/views/settings/merchant/SavedExternalAccounts.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Saved external accounts", active: true },
            ],
            pageTitle: "Saved external accounts",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/archived-data",
          name: "archived-data",
          component: () => import("@/views/settings/merchant/ArchivedApplications.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Account configuration - Archived data", active: true },
            ],
            pageTitle: "Account configuration",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/product-list",
          name: "merchant-product-list",
          component: () => import("@/views/settings/merchant/ProductList.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Account configuration - products", active: true },
            ],
            pageTitle: "Account configuration",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/payment-options",
          name: "payment-options",
          component: () => import("@/views/settings/merchant/PaymentOptions.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Account configuration - payment options", active: true },
            ],
            pageTitle: "Account configuration",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/key-contacts",
          name: "key-contacts",
          component: () => import("@/views/settings/merchant/Contacts.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Account configuration - contacts", active: true },
            ],
            pageTitle: "Account configuration - contacts",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/payment-services",
          name: "payment-services",
          component: () => import("@/views/settings/merchant/PaymentServices.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Payment services", active: true },
            ],
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        // =============================================================================
        // Pages Routes
        // =============================================================================

        {
          path: "/pages/knowledge-base",
          name: "page-knowledge-base",
          component: () => import("@/views/pages/KnowledgeBase.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Pages" },
              { title: "KnowledgeBase", active: true },
            ],
            pageTitle: "KnowledgeBase",
            rule: "editor",
          },
        },
        {
          path: "/pages/knowledge-base/category",
          name: "page-knowledge-base-category",
          component: () => import("@/views/pages/KnowledgeBaseCategory.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Pages" },
              { title: "KnowledgeBase", url: "/pages/knowledge-base" },
              { title: "Category", active: true },
            ],
            parent: "page-knowledge-base",
            rule: "editor",
          },
        },
        {
          path: "/pages/knowledge-base/category/question",
          name: "page-knowledge-base-category-question",
          component: () =>
            import("@/views/pages/KnowledgeBaseCategoryQuestion.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Pages" },
              { title: "KnowledgeBase", url: "/pages/knowledge-base" },
              { title: "Category", url: "/pages/knowledge-base/category" },
              { title: "Question", active: true },
            ],
            parent: "page-knowledge-base",
            rule: "editor",
          },
        },

        // =============================================================================
        // EXTENSIONS
        // =============================================================================
        {
          path: "/extensions/quill-editor",
          name: "extra-component-quill-editor",
          component: () =>
            import(
              "@/views/components/extra-components/quill-editor/QuillEditor.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Extra Components" },
              { title: "Quill Editor", active: true },
            ],
            pageTitle: "Quill Editor",
            rule: "editor",
          },
        },
        {
          path: "/extensions/i18n",
          name: "extra-component-i18n",
          component: () =>
            import("@/views/components/extra-components/I18n.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Extensions" },
              { title: "I18n", active: true },
            ],
            pageTitle: "I18n",
            rule: "editor",
          },
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/callback",
          name: "auth-callback",
          component: () => import("@/views/Callback.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/admin/login",
          name: "page-login",
          component: () => import("@/views/pages/login/Login.vue"),
          meta: {
            rule: "editor",
            requiresAuth: true,
          },
        },
        {
          path: "/login",
          name: "partner-login",
          component: () =>
            import("@/views/pages/login/partners/PartnerLogin.vue"),
          meta: {
            rule: "editor",
            requiresAuth: false,
          },
        },
        {
          path: "/login-as-partner",
          name: "login-as-partner",
          component: () => import("@/views/pages/login/LoginAsPartner.vue"),
          meta: {
            rule: "editor",
          },
        },

        {
          path: "/forgot-password",
          name: "admin-forgot-password",
          component: () => import("@/views/pages/ForgotPassword.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/resend-verification",
          name: "page-resend-verification",
          component: () => import("@/views/pages/ResendVerification"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/set-password/:token",
          name: "user-forgot-password",
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store
              .dispatch("auth/checkTokenExpiry", payload)
              .then(() => {
                next();
              })
              .catch((error) => {
                next("/pages/error-419");
              });
          },
          component: () => import("@/views/pages/setPassword.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/verify-email/:token",
          name: "user-account-verification",
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store.dispatch("auth/verifyMail", payload).then(() => {
              return next();
            }).catch((ex) => {
              return next("/pages/error-419");
            });
          },
          component: () => import("@/views/pages/verificationSuccess.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/reset-password",
          name: "page-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor",
            authRequired: false,
          },
        },
        {
          path: "/reset-password/:token",
          name: "user-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor",
            authRequired: false,
          },
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store.dispatch("auth/isResetTokenExpired", payload).then(() => {
              next();
            }).catch((ex) => {
              next("/pages/error-419");
            });
          },
        },
        {
          path: "/success",
          name: "reset-password-success",
          component: () => import("@/views/pages/Success.vue"),
          meta: {
            rule: "editor",
          },
        },

        {
          path: "/pages/error-419",
          name: "page-error-419",
          component: () => import("@/views/pages/Error419.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {
            rule: "editor",
          },
        },

        {
          path: "/pages/maintenance",
          name: "page-maintenance",
          component: () => import("@/views/pages/Maintenance.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/privacy-policy/:slug",
          name: "PrivacyPolicy",
          component: () => import("@/views/pages/PrivacyPolicy.vue"),
          meta: {
            rule: "editor",
          },
        },
      ],
    },
    {
      path: "",
      component: () => import("@/layouts/Terms/guest.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/pages/terms-and-conditions/:id",
          name: "TermsAndConditions",
          component: () => import("@/views/pages/Terms.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/onboard/:onboardingId",
          name: "onboard-application",
          component: () => import("@/views/pages/partners/ReviewData"),
          meta: {
            breadcrumb: [],
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
      ]
    },
    {
      path: "",
      component: () => import("@/layouts/guest/guest.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/pages/terms-and-conditions/:id",
          name: "TermsAndConditions",
          component: () => import("@/views/pages/Terms.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/payments/:id",
          name: "payments",
          component: () => import("@/views/guest/newPayment.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Merchant" },
              { title: "Payments", active: true },
            ],
            rule: "editor",
          },
        },
        {
          path: "/:paymentRequestId",
          name: "paymentPage",
          component: () => import("@/views/guest/newPayment.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/newpayments/:id",
          name: "newpayments",
          component: () => import("@/views/guest/newPayment.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Merchant" },
              { title: "Payments", active: true },
            ],
            rule: "editor",
          },
        },
        {
          path: "/payment-request/merchant/:pageId",
          name: "static-page-payments",
          component: () => import("@/views/guest/new-static-payment.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/payment/success",
          name: "payment-success",
          component: () => import("@/views/pages/paymentSuccess.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/payment/license",
          name: "payment-license",
          component: () => import("@/views/guest/LicenseCardMobile.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/payment/error",
          name: "payment-error",
          component: () => import("@/views/pages/paymentError.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/payment/forbidden",
          name: "payment-forbidden",
          component: () => import("@/views/pages/PaymentForbidden.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/payment-legals/:id/:legalid",
          name: "paymentLegals",
          component: () => import("@/views/guest/legals.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Merchant" },
              { title: "Legals", active: true },
            ],
            rule: "editor",
          },
        },
      ],
    },

    {
      path: "/view-log/email/:prid/:logid",
      name: "emailLogDetail",
      component: () => import("@/views/pages/partners/paymentRequest/PaymentRequestDetails/MainDetails/EmailLogDetail.vue"),
      meta: {
        rule: "editor",
      },
    },

    {
      path: "/thank-you/submit",
      name: "thankyou",
      component: () => import("@/views/ThankYouStatic.vue"),
      meta: {
        rule: "editor",
      },
    },

    {
      path: "/request/expire",
      name: "requestExpire",
      component: () => import("@/views/RequestExpire.vue"),
      meta: {
        rule: "editor",
      },
    },

    {
      path: "",
      name: "createLogin",
      component: () => import("@/layouts/CreateLogin/CreateLogin.vue"),

      children:[
        {
          path: "/:partnerId/authenticate",
          name: "authenticate",
          component: () => import("@/views/pages/authentication/authenticate.vue"),
          beforeEnter: (to, from, next) => {

            const payload = {
              partnerId: to.params.partnerId,
              hash:to.query.hash
            };
            store.dispatch("partner/verifySetupToken", payload).then((response) => {
              const responseData = response.data.data;

              if (responseData.isTokenExpire) {
                return next("request/expire");
              }

              return next();
            }).catch(ex => {
              return next("request/expire");
            });
          },
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/:partnerId/verify-otp",
          name: "verifyOtp",
          component: () => import("@/views/pages/authentication/verifyOtp.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/:partnerId/setup-credentials",
          name: "setupCredentials",
          component: () => import("@/views/pages/authentication/SetupCredentials.vue"),
          meta: {
            rule: "editor",
          },
        },
      ]
    },
    // Redirect to 404 page, if no match found
  ],
});

router.afterEach((to) => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");

  if (appLoading) {
    appLoading.style.display = "none";
  }
  document.title = to.meta.pageTitle || process.env.VUE_APP_NAME;
});

//this method is calling in each router navigate
//commented cause of reset-password link doesn't required to auth
router.beforeEach((to, from, next) => {

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    store.state.redirectAfterLogin.name = to.name;
    store.state.redirectAfterLogin.params = to.params;

    let userInfo = JSON.parse(localStorage.getItem("userInfo"));

    if (userInfo) {
      const firstPath = to.path.split("/")[1];

      if (firstPath === "admin" && userInfo && userInfo.role !== "admin") {
        return next({ path: "/dashboard/" });
      }
    }

    auth(to, from, next);
  } else if (to.matched.some((record) => record.meta.requiresMerchantAdminAuth)) {
    store.state.redirectAfterLogin.name = to.name;
    store.state.redirectAfterLogin.params = to.params;

    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      const firstPath = to.path.split("/")[1];

      if (firstPath === "admin" && userInfo.userType !== "admin" && userInfo.userType !== "staffAdmin") {
        return next({ path: "/dashboard/" });
      }
    }
    auth(to, from, next);
  } else {
    return next();
  }
});

export default router;

